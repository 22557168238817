import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  filledInputs: number,
  countdown: any, 
  resendDisabled: boolean,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string="";
  submitButtonColor: any = configJSON.submitButtonColor;
  authOtpCallID:any
  timer:  NodeJS.Timeout | undefined | number;
  otpTokenId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      countdown:30, 
      resendDisabled: false, 
      otp: "",
      filledInputs: 0,
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id ) {
      const apiRequestCallId = await message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId===this.authOtpCallID && responseJson){
       
       this.getOtp(responseJson)
      }

      if(apiRequestCallId===this.otpTokenId && responseJson){
        if(responseJson.meta  && responseJson.data ){
          window.localStorage.setItem("otpAuthToken",responseJson.meta.token)
          }   
          
      }
     }
    // Customizable Area End
  }

  // Customizable Area Start
  getOtp=(responseJson:any)=>{
    if(!responseJson.errors && responseJson.messages){
      toast.success(responseJson.messages[0].otp)
      this.props.navigation.navigate("NewPassword") 
      }   
      else{
        if(responseJson.errors[0].pin){
          toast.error(responseJson.errors[0].pin)
        }
        else{
          toast.error(responseJson.errors[0].otp)
        }
      }
  }

  
  async componentDidMount() {
    super.componentDidMount();
    this.startCountdown();
  }

  startCountdown() {
    this.timer = setInterval(() => {
        this.setState((prevState) => ({ countdown: prevState.countdown - 1 }), () => {
            if (this.state.countdown === 0 ) {
                clearInterval(this.timer as number | undefined);
                
            }
        });
    }, 1000);
}



  async submitOtp() {
    if (!this.state.otp || this.state.otp.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorOtpNotValid);
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
      // console.log("entered is from forgot password!");
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType
      };

      //GO TO REQUEST STATE
      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyForgotPasswordOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        token: this.state.otpAuthToken ? this.state.otpAuthToken : "",
        otp_code: this.state.otp ? this.state.otp : ""
      };

      const httpBody = {
        data: data
      };

      //requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        token: this.state.otpAuthToken
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint + this.state.otp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(JSON.stringify({}))
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    // console.log("requestMessage.id is: " + requestMessage.id);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

   
  btnSubmitOTPProps = {
    onPress: () => this.submitOtp()
  };
 
  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text })
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric"
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

   

   handleOtpChange = (otp:any) => {
      this.setState({ otp, filledInputs: otp.length });
  }
  
  verifyOtp=(e:any)=> {     
    e.preventDefault();
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      if (!this.state.isFromForgotPassword) {
        const headers = {
          "Content-Type": configJSON.apiVerifyOtpContentType,
          
        };
        const reqbody={
          data:{
            token: window.localStorage.getItem("otpAuthToken"),
            otp_code:this.state.otp
          }
        }
       
        this.authOtpCallID = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'bx_block_forgot_password/otp_confirmations'
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(reqbody)
        );
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiVerifyOtpMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    isValidEmail = (email: any) => {
      if (!email || typeof email !== 'string') {
        return false; 
      }
      return true;
    };
   
    ResendOtpRequest() {
      let input=localStorage.getItem("userInput")
      if (input !== null) {
        let result=JSON.parse(input)

      const sendOtpHeader = {
        "Content-Type": "application/json"
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.otpTokenId = requestMessage.messageId;
      if (this.isValidEmail(result.email)) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        'bx_block_forgot_password/otps/:otp_id/resend_email_otp'
        
      )}
      else{
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'bx_block_forgot_password/otps/:otp_id/resend_sms_otp')
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(sendOtpHeader)
      );
      const data = {
        attributes: result
      };
      const httpBody = {
        data: data
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiVerifyOtpMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    }

  // Customizable Area End
}
