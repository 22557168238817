import React from "react";
import DashboardController, { Props } from "./DashboardController";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { arrowDown, success_event, active_calendar } from "./assets";
import { theme } from "./Template.web";
import Modal from "@material-ui/core/Modal";
import Checkbox from '@material-ui/core/Checkbox';



// @ts-ignore

import RangePicker from "react-range-picker";





export default class CalendarComponent extends DashboardController {
   
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
          


const sliceString= this.state.activateMessage.split('!'); 

        return (
            // Customizable Area Start
            <>

                <div
                    className={`content-wrapper ${this.state.isPopupVisible ? "right-popup-open"
                        : ""}`}
                >
                    <div className="calender-main">
                        <div className="custom-calender">
                                <div className="calendar-container">
                                    <Calendar
                                    data-testid="calendar"
                                     tileContent={this.customTileContent}
                                        formatMonthYear={(locale:any, date:any) => this.formatMonthLabel(locale, date)}
                                        prev2Label={null}
                                        next2Label={null}
                                    />
                                </div>
                        </div>
                        <div className="custom-right-sidebar">
                            <div className="right-sidebar-top">
                                <button data-testid="addevent" className="button" onClick={this.openPopup}>+ Add Event</button>
                            </div>

                            <div className="event-blocks">
                                <h3>Events of the Month</h3>
                                <div className="event-block">
                                    <h5>1st June Events</h5>
                                    <ul>
                                        <li>
                                            <h5 className="event-title">Anna’s Birthday</h5>
                                            <span className="event-remind">1h Early Reminder</span>
                                        </li>
                                        <li className="unsuccess">
                                            <h5 className="event-title">Elie Joined the Sales Team</h5>
                                            <span className="event-remind">Instant Reminder</span>
                                        </li>
                                        <li>
                                            <h5 className="event-title">Sam Joined the Dev Team</h5>
                                            <span className="event-remind">Instant Reminder</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="event-block">
                                    <h5>6th June Events</h5>
                                    <ul>
                                        <li>
                                            <h5 className="event-title">Ray’s Birthday</h5>
                                            <span className="event-remind">1h Early Reminder</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="event-block">
                                    <h5>24th June Events</h5>
                                    <ul>
                                        <li>
                                            <h5 className="event-title">Vikas’s Anniversary</h5>
                                            <span className="event-remind">1h Early Reminder</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.isPopupVisible && (
                            <>
                                <div className="popup-overlay"></div>
                                <div className="right-popup">
                                    <div className="popup-header">
                                        <h5>Add Event</h5>
                                        <span data-testid="close" className="close" onClick={this.openPopup}></span>
                                    </div>

                                    <div className="event-scoller">
                                        <span className="prev-event event-btn"></span>
                                        <h6>01 Dec 2023-31 Dec 2023</h6>
                                        <span className="next-event event-btn"></span>
                                    </div>

                                    <div className="form-block">
                                        <form onSubmit={e => {this.handleEventFormSubmit(e) }} >
                                            <div className="form-field">
                                                <span className="label">Event Name</span>
                                                <div className="control">
                                                    <input type="text" className="control-input" placeholder="Christmas Winter Week Holidays" name="event_name" 
                                                       onChange={(e) => {this.setState({ createEventData: { ...this.state.createEventData, event_name: e.target.value } })}}
                                                    id="event-name" />
                                                </div>
                                            </div>

                                            <div className="form-field half checkbox-field">
                                                <div className="field date-field-custom">
                                                    <span className="label">Date</span>
                                                    <div className="control">
                                                        {this.state.isMultiple ? (

                                                            <RangePicker
                                                                className="control-input pickdate"
                                                                data-testid="multipledate"
                                                                onDateSelected={(startDate:any, endDate:any)=>
                                                                    {this.setState({ createEventData: { ...this.state.createEventData, start_date:startDate,end_date:endDate } })}}
                                                                placeholder={({ startDate, endDate }: any) => (
                                                                    <div className="placeholder">

                                                                        {!startDate || !endDate
                                                                            ? "Pick a date"
                                                                            : `${this.formatDate(startDate)} - ${this.formatDate(endDate)}`}{" "}

                                                                    </div>
                                                                )}
                                                            />

                                                        ) : (

                                                            <RangePicker
                                                              data-testid="singledate"
                                                                className="control-input pickdate"
                                                                disableRange
                                                                closeOnSelect
                                                                onDateSelected={(startDate:any)=>
                                                                    {this.setState({ createEventData: { ...this.state.createEventData, start_date:startDate,} })}}
                                                                placeholder={({ startDate }: any) => (
                                                                    <div className="placeholder">
                                                                        {!startDate ? "Pick a date" : `${this.formatDate(startDate)}`}{" "}
                                                                    </div>
                                                                )}
                                                            />
                                                        )}


                                                        <span
                                                            style={{
                                                                position: "absolute",
                                                                right: "12px",
                                                                top: "45%",
                                                                marginTop: "-8px",
                                                                pointerEvents: "none"
                                                            }}

                                                        >{<img src={active_calendar} alt="logo" height={20} width={20} />}</span>
                                                    </div>
                                                </div>
                                                <div className="field">
                                                    <div className="control">
                                                        <div className="checkbox">
                                                            <Checkbox
                                                                defaultChecked
                                                                color="primary"
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            />

                                                            <label >Restricted</label>
                                                        </div>
                                                        <div className="checkbox">
                                                            <Checkbox
                                                                data-testid="multipleCheckbox"
                                                                onChange={this.handleCheckboxChange}
                                                                color="primary"
                                                                name="isMultiple"
                                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            />

                                                            <label >Multiple</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-field">
                                                <span className="label">Description</span>
                                                <div className="control">
                                                    <textarea placeholder="Describe About The Event" 
                                                     onChange={(e:any) => {this.setState({ createEventData: { ...this.state.createEventData, description: e.target.value } })}}
                                                    className="control-input" name="description" id="" cols={30} rows={5}></textarea>
                                                </div>
                                            </div>
                                            <div className="form-field select-field">
                                                <div className="control">
                                                    <div>
                                                        <select  className="control-input" name="day_type" 
                                                        onChange={(e:any) => {this.setState({ createEventData: { ...this.state.createEventData, day_type: e.target.value } })}}
                                                        >
                                                            <option value="Choose Day Type">Choose Day Type</option>
                                                            <option value="full_day">Full Day</option>
                                                            <option value="half_day">Half Day</option>
                                                        </select>
                                                    </div>

                                                    <img className="select-arrow" src={arrowDown} alt="logo" width={20} height={20} />

                                                </div>
                                            </div>

                                            <div className="form-field checkbox-field">
                                                <div className="control">
                                                    <div className="checkbox">
                                                        <Checkbox
                                                            defaultChecked
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                        <label id="event_check1">Notify People via Infoboard</label><br />
                                                    </div>
                                                    <div className="checkbox">
                                                        <Checkbox
                                                            
                                                            data-testid="reprocess"
                                                            checked={this.state.isChecked}
                                                            onChange={this.handleCheckboxChange}
                                                            color="primary"
                                                            name="isChecked"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                        <label id="event_check2">Re-process Leave Applications Based On This Holiday</label>


                                                    </div>
                                                    {this.state.isChecked && (
                                                        <p>
                                                            (Leaves that are already applied for this holiday will be reprocessed and the balance will be adjusted accordingly.)
                                                        </p>
                                                    )}

                                                </div>
                                            </div>

                                            <div className="form-field otp-button d-flex">
                                                <div className="control">
                                                    <input type="submit" style={(this.state.createEventData.event_name && this.state.createEventData.description && this.state.createEventData.day_type && this.state.createEventData.start_date)?webStyle.saveEnable : webStyle.saveDisable} data-test-id="save" className="button" value="Save" />
                                                    <input type="reset" className="button reset" value="Cancel" />
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div >
                <Modal
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    open={this.state.open && this.state.userType !== "employee"}
                    onClose={this.handleOpenModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <>
                        <div style={webStyle.paper as React.CSSProperties}>
                            <div className="center-block-header final-screen text-center">
                                <h3 style={{ color: "black",fontSize:"28px" }}>Congratulations!</h3>
                                <p style={{ color: "#707070",fontSize:"16px" }}>Event Added Successfully</p>
                                <div className="header-logo">
                                    <img src={success_event} alt='logo' width={284} height={278} />
                                </div>
                            </div>
                        </div>
                    </>
                </Modal>
            </>
            // Customizable Area End
        );
    }
}
const webStyle = {
    paper: {
        position: "absolute",
        width: 700,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(8, 8, 8)
    },
    saveEnable:{

    },
    saveDisable:{
        backgroundColor: "#F7F7F7",
        color:"#878787",
        cursor: "not-allowed",
        pointerEvents: "none",
        border:"none"
    }
}