import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import toast from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  subHeading: string;
  screen: number;
  pronounce: string;
  higher_education: string;
  phoneNumber: string;
  brief_about_employee: string;
  homeTown: string;
  address: string;
  ask_employee_about_expertise: string;
  bloodGroup: string;
  profile_img: any;
  profile: any;
  profileUpdated: boolean;
  full_phone_number: any;
  countryCode: any;
  editAdminFormData: any,
  userDataLoading: boolean,
  openModal:boolean


  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SetUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileInputRef: any = "";
  getEditProfileImage: string = "";
  getAdminProfileDetail: string = "";
  getEditProfileAdminId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStep: 0,
      subHeading: "Confirm information provided by admin",
      screen: 0,
      pronounce: '',
      higher_education: '',
      phoneNumber: '',
      brief_about_employee: '',
      homeTown: '',
      address: '',
      ask_employee_about_expertise: '',
      bloodGroup: '',
      profile_img: '',
      profile: '',
      profileUpdated: false,
      full_phone_number: "",
      countryCode: '',
      editAdminFormData: [],
      userDataLoading: false,
      openModal: false,


      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fileInputRef = React.createRef()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.errors) {
          toast.error(responseJson?.errors[0]?.token);
        } else {
          switch (apiRequestCallId) {
            case this.getEditProfileAdminId:
              this.getEditEmployeeFormData(responseJson,true)
              break;
            case this.getEditProfileImage:
              this.setState({ profileUpdated: false })
              break;
            case this.getAdminProfileDetail:
              this.getEditEmployeeFormData(responseJson,false)
              break;
            default:
              break;
          }
        }
      }
    }
    // Customizable Area End
  }




  // web events
  getBorderColor = (active: any, completed: any) => {
    if (completed) {
      return "1px solid #FDD100";
    } else if (active) {
      return "1px solid #FDD100";
    } else {
      return "1px solid #F1F1F1";
    }
  };
  getBackgroundColor = (active: any, completed: any) => {
    if (completed) {
      return "#FDD100";
    } else if (active) {
      return "#FDD100";
    } else {
      return "#F1F1F1";
    }
  };
  getEditEmployeeFormData = (responseJson: any,route:any) => {
    this.setState({
      profile_img: responseJson.data.attributes.image,
      editAdminFormData: responseJson.data,
      userDataLoading: false
    });
    if(route){
      window.history.pushState({}, "", "/EmailAccountLoginBlock");
      window.location.reload()
    }
  }
  handleOpenModal=()=>{
    this.setState({
      openModal: true,
    });
  }
  handleImageUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    this.setState({ profile: file, profileUpdated: true })
    reader.onloadend = () => {
      this.setState({ profile_img: reader.result, openModal: false });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  handleProfileImageClick = () => {
    this.fileInputRef.current.click();
  };
  handlePhoneNumberChange = (phone: string, countryData: any) => {
    this.setState({ countryCode: countryData, full_phone_number: phone })
  }
  updateProfileImage = () => {
    let userToken = window.sessionStorage.getItem("tempToken");

    let formdata = new FormData()

    const httpHeader = {
      token: userToken
    };

    formdata.append('image', this.state.profile, this.state.profile.name);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEditProfileImage = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateImageEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateImageAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getAdminProfile = () => {
    this.setState({
      userDataLoading: true
    })
    let userToken = window.sessionStorage.getItem("tempToken");

    const httpHeader = {
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdminProfileDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAdminProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAdminProfileAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleAdminFormSubmit = () => {
    if (this.state.profileUpdated) {
      this.updateProfileImage()
    }

    let userToken = window.sessionStorage.getItem("tempToken");

    let formdata = new FormData()

    const httpHeader = {
      token: userToken
    };
    const { pronounce, full_phone_number, ask_employee_about_expertise, homeTown, address, bloodGroup, brief_about_employee, higher_education } = this.state
    formdata.append("preferred_pronounce", pronounce);
    formdata.append("full_phone_number", full_phone_number);
    formdata.append("home_town", homeTown);
    formdata.append("address", address);
    formdata.append("blood_group", bloodGroup);
    formdata.append("higher_education", higher_education);
    formdata.append("brief_about_employee", brief_about_employee);
    formdata.append("ask_employee_about_expertise", ask_employee_about_expertise);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEditProfileAdminId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateAdminProfileEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(httpHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateAdminProfileAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  handleGotoSetProfile = () => {
    const { attributes: { address = "", full_phone_number = "", preferred_pronounce = "", home_town = "", blood_group = "", higher_education = "", brief_about_employee = "", ask_employee_about_expertise = "" } = {} } = this.state.editAdminFormData
    this.setState({
      screen: 1,
      pronounce: preferred_pronounce,
      full_phone_number: full_phone_number,
      homeTown: home_town,
      address: address,
      bloodGroup: blood_group,
      higher_education: higher_education,
      ask_employee_about_expertise: ask_employee_about_expertise,
      brief_about_employee: brief_about_employee
    })
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getAdminProfile()
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.screen !== prevState.screen) {
      if (this.state.screen === 1) {
        this.setState({
          subHeading: "Provide your personal information",
          activeStep: 1
        })
      } else {
        this.setState({
          subHeading: "Confirm information provided by admin",
          activeStep: 0
        })
      }
    }
  }

  // Customizable Area End
}
