import React from "react";
// Customizable Area Start
import { createTheme , styled} from "@material-ui/core/styles";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {
  Grid,
  Box,
  Button,
  Typography,
  Paper, TableBody, TableCell, TableRow, TableContainer, Table, TableHead ,Link,IconButton
} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// Customizable Area End
import DashboardController, { Props } from "./DashboardController";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});
export default class TemplateAdded extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    const KPIData = [
      {
        points: '20 Points',
        kpi: 'KPI 1',
        details: 'Sales Goal, Sales Goal : 1000000, Pro Rata Basis, MQR : 70%'
      },
      {
        points: '10 Points',
        kpi: 'KPI 2',
        details: 'Payment Collections, Collect 90% of Previous Month Sales, Collect 80% of Previous Month Sales'
      },
      {
        points: '10 Points',
        kpi: 'KPI 3',
        details: 'Conversions, Achieve : 70% Sales Conversions'
      },
      {
        points: '10 Points',
        kpi: 'KPI 4',
        details: 'Daily Average Visits: 10'
      },
      {
        points: '10 Points',
        kpi: 'KPI 5',
        details: 'Weekly Stock, Check Stock for 2 Items Each Week, Saturdays'
      }
    ];

    const breadcrumbs = [
      <Link
        key="1"
        href="/TemplateAdded"
        // onClick={this.handleClick}
      >
        <Typography style={{color: 'black', fontSize: "18px", fontWeight: 600 ,fontFamily: 'Open Sans',}}>Template Added</Typography>
      </Link>,
    ];

    const CustomTextField = styled(Table)({
      "& .MuiTableCell-root": {
              borderBottom: "none",
          }});
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <form style={webStyle.template as React.CSSProperties}>
              <div style={webStyle.filterHeader}>
              <Breadcrumbs separator="" aria-label="breadcrumb">
              <div>
                  <IconButton style={{color:"black" , fontSize: "small", padding: "0px",background:"none" }} href="/Template">
                     <ArrowBackIosIcon/>
                  </IconButton>
                </div>
               {breadcrumbs}
              </Breadcrumbs>
              </div>
            </form>
        <Box style={webStyle.MainContainer}>
          <Grid container spacing={1} style={webStyle.Grid1}>
            <Grid item xs={9}>
              <Typography style={webStyle.Typography1}>Test Template</Typography>
              <Typography style={webStyle.Typography2}>Over 92% of computers are infected with Adware and spyware. </Typography>
              <Typography style={webStyle.Typography3}>and even when it is it almost always leaves broken Windows.</Typography>
            </Grid>
            <Grid item xs={3}>
              <Box >
                <Button style={{
                  fontFamily: 'Open Sans',
                  backgroundColor: "FDD100",
                  color: "black",
                  marginRight: "20px",
                  width: "170px",
                  fontSize: "medium",
                  fontWeight: 550,
                  textTransform: "none"
                }}
                  data-test-id="yesClick"
                  onClick={() => { this.navigateToEditTemplate2() }}>
                  Edit Template
                </Button>
                <Button variant="outlined" style={{
                  fontFamily: 'Open Sans',
                  backgroundColor: "FDD100",
                  color: "black",
                  width: "170px",
                  fontSize: "medium",
                  fontWeight: 550,
                  textTransform: "none"
                }}>
                  Tag Template
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box >
            <CustomTextField>
            <TableContainer style={{width: "100%", marginLeft: "10px"}}>
              <Table aria-label="kpi table" style={{borderTop:"1px solid rgba(224, 224, 224, 1)", borderLeft:"1px solid rgba(224, 224, 224, 1)"}}>
                <TableHead style={{borderBottom:"1px solid rgba(224, 224, 224, 1)"}}>
                  <TableRow >
                    <TableCell style={webStyle.TableCell1}>Points</TableCell>
                    <TableCell style={webStyle.TableCell1}>KPI</TableCell>
                    <TableCell style={webStyle.TableCell2}>Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{borderTop:"1px solid rgba(224, 224, 224, 1) ! important"}}>
                  {KPIData.map((row, index) => (
                    <TableRow key={index} style={{marginBottom:"10px"}}>
                      <TableCell style={{...webStyle.TableCell3 }} >
                        <div style={{display:"Flex",justifyContent:"center",alignItems:"center"}}>
                        <span style={{height:"1px",width:"100px",border: " 1px solid rgba(224, 224, 224, 1)"}} /><p style={{padding:"0px 5px 0px 5px"}}>{row.points}</p><span style={{height:"1px",width:"100px",border:"1px solid rgba(224, 224, 224, 1)"}} />
                        </div>
                        </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          style={{
                            paddingBottom: "2px", backgroundColor: '#FDD100', color: 'black', width: "180px", borderRadius: "10px", fontWeight: 700
                          }}
                        >
                          {row.kpi}
                        </Button>
                      </TableCell>
                      <TableCell style={webStyle.TableCell4}>
                        <Typography style={webStyle.Typography4}>{row.details}</Typography>
                      </TableCell>       {/* <Box style={{ borderColor: '1px solid black'}} />
                        <Typography >{row.points}</Typography> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </CustomTextField>
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  MainContainer: {
    cursor: "pointer", margin: "20px", borderRadius: "10px"
  },
  Grid1: {
    padding: "15px", marginBottom: "20px"
  },
  Typography1: {
    color: 'black', fontSize: "18px", fontWeight: 600
  },
  Typography2: {
    color: 'black', fontSize: "14px", lineHeight: 2.5
  },
  Typography3: {
    color: 'black', fontSize: "14px", lineHeight: 0.5
  },
  TableCell1: {
    fontFamily: 'Open Sans', color: "black", fontSize: "18px", fontWeight: 550, paddingLeft: "100px"
  },
  TableCell2: {
    fontFamily: 'Open Sans', color: "black", fontSize: "18px", fontWeight: 550, display: "flex", justifyContent: "center", 
  },
  TableCell3: {
    // paddingLeft: "30px"
  },
  TableCell4: {
    // backgroundColor: '#F3F4F5', borderRadius: "10px"
  },
  Typography4: {
    color: 'Black', lineHeight: 1.5,
    backgroundColor: '#F3F4F5', borderRadius: "10px" ,padding: "12px"
  },
  filterHeader: { 
    padding: "0px 0px 0px 22px", 
    display: "flex", 
    alignItems: "flex-start", 
    gap: "20px"
   },
   template: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px 10px",
    backgroundColor: '#F3F4F5',
    position: "relative",
    height: "72px",
    alignItems: "center"
   }

};
// Customizable Area End
