import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    Card,CardContent
} from "@material-ui/core";
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { noun_clock, Kpi_report, carrer_ladder, Kpi_gamecard, Kpi_review, Kpi_statistics } from "./assets"
// Customizable Area End

import DashbordController, {
    Props
} from "./DashboardController";

export default class EmployeeDashboard extends DashbordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
         // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={{ background: "#F3F4F5", height: "64px", marginTop: "32px",marginRight:"-20px" }} >
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid className="first" style={{ padding: '19px 0px 21px 38px' }} item>
                    <Typography style={{ color: "black", fontSize: "18px", fontWeight: 400 }}>Home</Typography>
                </Grid>
                <Grid className="second" style={{ padding: '0px 44px 0px 0px' }} item>
                    <Box style={{
                        background: "white",
                        height: "56px",
                        width: "166px",
                        borderRadius: "8px",
                        border: "0.5px solid #FFCC02",
                    }}>
                        <Grid container style={{
                            alignItems: "center",
                            paddingLeft: "5px"
                        }}>
                            <Grid style={{
                                width: "100%",
                                maxWidth: "30%",
                            }} item>
                                <img src={noun_clock} alt="logo" />
                            </Grid>
                            <Grid item>
                                Check-in<Grid item>
                                    00 hrs 00 mins
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid container alignItems="center" style={{ padding: '0 32px' }}>
                <Grid className="first" item style={{ marginTop: "24px" }}>
                    <Typography style={{ color: "black", fontSize: "18px", fontWeight: 400 }}>Hi Shreya, Good morning!</Typography>
                </Grid>
                <Box className="goal-process" style={{ width: "100%", padding:"45px", marginTop: "28px", background: "#F5F5F5", border: "0.5px solid rgba(0,0,0,0.24)", borderRadius: "8px" }} >
                    <Grid container justifyContent="space-between" alignItems="center" style={{ height: "100%", }}>
                        <Grid item>
                            <Typography style={{ color: "black", fontSize: "21px", fontWeight: 100 }}>Goal Progress</Typography>
                        </Grid>
                        <Grid container>
                            <Grid className="second" style={{ marginRight: "30px",width:'70px',height:"70px" }} item>

                            <CircularProgressbar value={44} text={`${44}%`} strokeWidth={10}
                            styles={buildStyles({
                                pathColor: `#FDD001`, 
                                textColor: '#333', 
                                trailColor: '#d6d6d6',
                              })}
                             />;


                            </Grid>
                            <Grid className="third" style={{ color: "#000", fontSize: "18px", fontWeight: 400 }} item>
                                Achieved for this month
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Grid className="goal-card-box" container alignItems="center" style={{ margin: '32px 0' }}>
                    <Grid alignItems="center" item xs={12} md={6} xl={3}>

                        <Card style={webStyle.root as React.CSSProperties} variant="outlined">
                            <CardContent>
                                <img src={Kpi_report} alt="logo" width={87} height={114} />


                                <Typography variant="body2" component="p">
                                    Daily KPI Report

                                </Typography>
                            </CardContent>

                        </Card>
                    </Grid>
                    <Grid alignItems="center" item xs={12} md={6} xl={3}>

                        <Card style={webStyle.root as React.CSSProperties} variant="outlined">
                            <CardContent>
                                <img src={Kpi_gamecard} alt="logo" width={144} height={105} />
                                <Typography variant="body2" component="p">
                                    KPI Gamecard

                                </Typography>
                            </CardContent>

                        </Card>
                    </Grid>
                    <Grid alignItems="center" item xs={12} md={6} xl={3}>

                        <Card style={webStyle.root as React.CSSProperties} variant="outlined">
                            <CardContent>


                                <img src={Kpi_statistics} alt="logo" width={101} height={105} />
                                <Typography variant="body2" component="p">
                                    Statistics
                                </Typography>
                            </CardContent>

                        </Card>
                    </Grid>
                    <Grid alignItems="center" item xs={12} md={6} xl={3}>

                        <Card style={webStyle.root as React.CSSProperties} variant="outlined">
                            <CardContent>
                                <img src={carrer_ladder} alt="logo" width={144} height={92.03} />


                                <Typography variant="body2" component="p">
                                    Career Ladder Chart

                                </Typography>
                            </CardContent>

                        </Card>
                    </Grid>
                    <Grid alignItems="center" item xs={12} md={6} xl={3}>

                        <Card style={webStyle.root as React.CSSProperties} variant="outlined">
                            <CardContent>
                                <img src={Kpi_review} alt="logo" width={101} height={98} />


                                <Typography variant="body2" component="p">
                                    Self KPI Review

                                </Typography>
                            </CardContent>

                        </Card>
                    </Grid>
                </Grid>
            </Grid>

        </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    container: {
        width: "100%",
        height: "100%"
    },
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};
// Customizable Area End
