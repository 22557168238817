import React from "react";

// Customizable Area Start
import {like, dislike, badge } from "../../dashboard/src/assets";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { editable_icon, user_profile, like_icon, poster, close } from "./assets";
import "../../dashboard/src/dashboard.css"
import "react-phone-input-2/lib/bootstrap.css";
import Modal from "@material-ui/core/Modal";
import { contactInfo } from "../../dashboard/src/assets";


// Customizable Area End

import CustomisableUserProfilesController, {
  Props,
  configJSON,
} from "./CustomisableUserProfilesController";
import PhoneInput from "react-phone-input-2";


export default class EmployeeProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { attributes: { employee_id = '', role = "", country = "", address = "", full_phone_number = "", email = "", timings = "", zone = "", state = "", first_name = "", last_name = "", preferred_pronounce = "", home_town = "", blood_group = "", date_of_birth = "", higher_education = "", brief_about_employee = "", ask_employee_about_expertise = "", department_name = "", reporting_to = "", date_of_joining = "", work_phone = "", company_name = "", city = "", designation = "", employee_type = "" } = {} } = this.state.editAdminFormData
    const validationSchemaforAdminProfile = Yup.object().shape({
      first_name: Yup.string().nullable().min(1, "You need to write characters").required("First Name is required"),
      full_phone_number: Yup.string().nullable().required("Contact Info is required"),
      last_name: Yup.string().nullable().min(1, "You need to write characters").required("Last Name is required"),
      address: Yup.string().max(500, "words limit 500"),
      home_town: Yup.string().nullable().min(1, "You need to write characters").required("Hometown is required"),
      blood_group: Yup.string().nullable().required("Blood group is required"),
      higher_education: Yup.string().nullable().min(1, "You need to write atleast 2 characters").required("Education is required"),
      ask_employee_about_expertise: Yup.string().max(1000, "maximum 1000 words are acceptable").required("Expertise is required"),
      preferred_pronounce: Yup.string().nullable().min(1, "You need to write atleast 2 characters").required("Pronounce is required"),
      brief_about_employee: Yup.string().max(2000, "maximum 2000 words are acceptable").required("Brief About Employee is required"),
      date_of_birth: Yup.string().nullable().test('age', "Employee must be at least 18 years old.", (value) => {
        const age = this.calculateAgeOfEmployee(value);
        if (age < 18) {
          return false;
        }
        return true;
      }).required("Date of Birth is required"),
    });

    const initialFormValues = () => {
      return {
        designation: designation,
        employee_id: employee_id,
        full_phone_number: full_phone_number,
        state: state,
        city: city,
        email: email,
        timings: timings,
        zone: zone,
        first_name: first_name,
        preferred_pronounce: preferred_pronounce,
        country: country,
        home_town: home_town,
        higher_education: higher_education,
        last_name: last_name,
        address: address,
        date_of_birth: date_of_birth,
        blood_group: blood_group,
        brief_about_employee: brief_about_employee,
        date_of_joining: date_of_joining,
        ask_employee_about_expertise: ask_employee_about_expertise,
        reporting_to: reporting_to,
        department_name: department_name,
        company_name: company_name,
        role: role,
        work_phone: work_phone,
        employee_type: employee_type,
      }
    }
    return (
      // Customizable Area Start
      <div className="content-wrapper">
        <div className="edit-form-wrapper">
          <div className="step-form">
            <div className="block-title">
              <h5 style={{ fontWeight: 'normal' }}>Profile</h5>
            </div>
          </div>

              <form className="edit-form form">
                <div className="profile-with-form">
                  <div className="emp-profile-side-wrapper">
                    <div className="emp-profile-details">
                      <div className="profile-icon">
                        <a href="#" className="user-profile" onClick={this.handleProfileImageClick}>
                          <img src={this.state.profile_img as string} alt="user-profile" style={{ width: "100px", height: '100px' }} />
                          <span className="pencil-icon"><img src={editable_icon} alt="pencil" style={{ height: "25px" }} /></span>
                        </a>
                        <input
                          type="file"
                          ref={this.fileInputRef}
                          accept="image/*"
                          style={{ display: 'none' }}
                          onChange={this.handleImageUpload}
                        />
                      </div>
                      <div>
                        <h3 style={{ fontWeight: 'bold',color:'black' }}>{first_name} {last_name}</h3>
                        <p style={{ color: '1CA0FF', margin: '0px' }}>{designation}</p>
                      </div>
                    </div>
                    <div className="emp-achivments-wrapper">
                      <div className="emp-achivments-icon-data">
                        <div className="achivments-icon">
                          <img style={{ width: '90px', height: '90px' }} src={like} alt="thums_up" width={40} height={40} />
                        </div>
                        <div className="point-table" style={{ justifyContent: 'center' }}>3</div>
                      </div>
                      <div className="emp-achivments-icon-data">
                        <div className="achivments-icon">
                          <img style={{ width: '90px', height: '90px' }} src={dislike} alt="thums_down" className="perform-icon" width={40} height={40} />
                        </div>
                        <div className="point-table" style={{ justifyContent: 'center' }}>1</div>
                      </div>
                      <div className="emp-achivments-icon-data">
                        <div className="achivments-icon">
                          <img style={{ width: '90px', height: '90px' }} src={badge} alt="thums_up" className="perform-icon" width={40} height={40} />
                        </div>
                        <div className="point-table" style={{ justifyContent: 'center' }}>6</div>
                      </div>
                    </div>
                  </div>
                  <div className="emp-profile-tab">
                    <h5 className={`${this.state.tabNumber == 1 ? "emp-tab-border":"emp-tab"}`} onClick={()=>{this.setState({tabNumber:1})}}>My Profile</h5>
                    <h5 className={`${this.state.tabNumber == 2 ? "emp-tab-border":"emp-tab"}`} onClick={()=>{this.setState({tabNumber:2})}}>Badges</h5>
                    <h5 className={`${this.state.tabNumber == 3 ? "emp-tab-border":"emp-tab"}`} onClick={()=>{this.setState({tabNumber:3})}}>E-Learning</h5>
                  </div>
                  {this.state.tabNumber == 1 && <div className="emp-personal-work-container">
                    <div className="emp-peronal">
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ fontSize: '20px', fontWeight: 'bold', color: 'black' }}>Personal Info</div>
                        <img src={editable_icon} alt="openModel" style={{ height: "25px" }} onClick={() => { this.setState({ openModal: true }) }} />
                      </div>
                      <div className="employeeContainer" style={{ color: 'black' }}>
                        <div className="employeeContainer-first">{configJSON.firstname}</div>
                        <div className="employeeContainer-second">{first_name}</div>
                      </div>
                      <div className="employeeContainer" style={{ color: 'black' }}>
                        <div className="employeeContainer-first">{configJSON.lastname}</div>
                        <div className="employeeContainer-second">{last_name}</div>
                      </div>
                      <div className="employeeContainer">
                        <div className="employeeContainer-first">{configJSON.emailID}</div>
                        <div className="employeeContainer-second">{email}</div>
                      </div>
                      <div className="employeeContainer" style={{ color: 'black' }}>
                        <div className="employeeContainer-first">{configJSON.preferredPronoun}</div>
                        <div className="employeeContainer-second">{preferred_pronounce}</div>
                      </div>
                      <div className="employeeContainer" style={{ color: 'black' }}>
                        <div className="employeeContainer-first">{configJSON.personalPhoneNumber}</div>
                        <div className="employeeContainer-second" style={{ color: '#0082d3' }}>{full_phone_number}</div>
                      </div>
                      <div className="employeeContainer" style={{ color: 'black' }}>
                        <div className="employeeContainer-first">{configJSON.hometown}</div>
                        <div className="employeeContainer-second">{home_town}</div>
                      </div>
                      <div className="employeeContainer" style={{ color: 'black' }}>
                        <div className="employeeContainer-first">{configJSON.address}</div>
                        <div className="employeeContainer-second">{address}</div>
                      </div>
                      <div className="employeeContainer" style={{ color: 'black' }}>
                        <div className="employeeContainer-first">{configJSON.bloodgroup}</div>
                        <div className="employeeContainer-second">{blood_group}</div>
                      </div>
                      <div className="employeeContainer" style={{ color: 'black' }}>
                        <div className="employeeContainer-first">{configJSON.dateOfBirth}</div>
                        <div className="employeeContainer-second">{date_of_birth}</div>
                      </div>
                      <div className="employeeContainer" style={{ color: 'black' }}>
                        <div className="employeeContainer-first">{configJSON.highestEducation}</div>
                        <div className="employeeContainer-second">{higher_education}</div>
                      </div>
                      <div className="employeeContainer" style={{ color: 'black' }}>
                        <div className="employeeContainer-first">{configJSON.briefAboutMe}</div>
                        <div className="employeeContainer-second">{brief_about_employee}</div>
                      </div>
                      <div className="employeeContainer" style={{ color: 'black' }}>
                        <div className="employeeContainer-first">{configJSON.askMeAbout}</div>
                        <div className="employeeContainer-second">{ask_employee_about_expertise}</div>
                      </div>
                    </div>
                    <div className="emp-work">
                      <div style={{ fontSize: '20px', fontWeight: 'bold', color: 'black' }}>
                        Work
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.employeeID}</div>
                        <div className="empContainer-second">{employee_id}</div>
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.workPhone}</div>
                        <div className="empContainer-second">{work_phone}</div>
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.department}</div>
                        <div className="empContainer-second">{department_name}</div>
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.designation}</div>
                        <div className="empContainer-second">{designation}</div>
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.role}</div>
                        <div className="empContainer-second">{role}</div>
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.reportingTo}</div>
                        <div className="empContainer-second">{reporting_to}</div>
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.staffingLocationCountry}</div>
                        <div className="empContainer-second">{country}</div>
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.staffingLocationState}</div>
                        <div className="empContainer-second">{state}</div>
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.staffingLocationCity}</div>
                        <div className="empContainer-second">{city}</div>
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.dateOfJoining}</div>
                        <div className="empContainer-second">{date_of_joining}</div>
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.employeeType}</div>
                        <div className="empContainer-second">{employee_type}</div>
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.companyName}</div>
                        <div className="empContainer-second">{company_name}</div>
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.timeZone}</div>
                        <div className="empContainer-second">{zone}</div>
                      </div>
                      <div className="empContainer">
                        <div className="empContainer-first">{configJSON.shiftingTimes}</div>
                        <div className="empContainer-second">{timings}</div>
                      </div>
                    </div>
                  </div>}
                  {this.state.tabNumber == 2 && 
                   <div className="emp-badges">
                  {[1,2,3,4,5,6,7,8].map((item,index)=>{
                    return(
                      <div className="emp-badges-card" key={index}>
                        <div>
                           <div style={{marginBottom:"15px"}}> <img style={{width:"70px"}} src={user_profile}/></div>
                           <div style={{display:'block',justifyContent:'center',alignItems:'center',borderRadius:'100%',background:'white',padding:'18px 15px'}}> <img style={{width:"40px"}} src={like_icon}/></div>
                         </div>
                        <div style={{margin:'10px'}}> 
                          <div style={{fontSize:'20px',fontWeight:"bold",color:'black'}}>Eddie Davis</div>
                          <div style={{color:'#707070',marginBottom:'16px'}}>Today 2:01 PM</div>
                          <div style={{marginBottom:'24px'}}><span style={{color:'#3FA1FC'}}>Eddie Davis</span> has given you Good performance badge </div>
                          <div style={{color:'black',fontWeight:"bold"}}>I liked the flows that you created. Good job!</div>
                        </div>
                        </div>
                    )
                  })}
                </div>}
                  {this.state.tabNumber == 3 && <div className="emp-e-learning">
                    {
                      [1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9].map((item,index)=>{
                        return(
                          <div className="emp-e-learning-card" key={index}>
                            <div><img src={poster}/></div>
                            <div style={{color:'black',fontSize:'18px',marginTop:'9px',marginBottom:'3px'}}>Pos Hardware More Options In Less Space</div>
                            <div style={{display:'flex'}}>
                              <div>11 hours ago</div>
                              <div style={{margin:'0px 5px'}}>.</div>
                              <div>879k views</div>
                            </div>
                        </div>
                        )
                      })
                    }
                  </div>}

                </div>
                </form>
          <Modal
            open={this.state.openModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Formik
              enableReinitialize
              initialValues={initialFormValues()}
              validationSchema={validationSchemaforAdminProfile}
              onSubmit={values => { this.handleAdminFormSubmit(values) }}
            >
              {({ errors, touched, handleChange, values, handleSubmit, setFieldValue}) => (
                <form onSubmit={handleSubmit} className="edit-form form">
                  <div
                    style={webStyle.modalContainer}
                    id="modal"
                    className="center-block-header text-start"
                  >
                    <div className="header-logo" style={{ display: 'flex', justifyContent: 'end' }} id="signupBloc2" >
                      <img
                        src={close}
                        alt="close"
                        style={{ marginBottom: "19px" }}
                        onClick={() => { this.setState({ openModal: false }) }}
                      />
                    </div>
                    <div className="block-content">
                      <div className="grid-row flex-row">
                        <div className="col-50" style={{margin:"12px 0px"}}>
                          <div className="form-group">
                            <label>{configJSON.firstname}</label>

                            <Field
                              type="text"
                              className={this.FormFieldForAdmin(errors.first_name, touched.first_name, values.first_name)}
                              name="first_name"
                              id="first_name"
                              onChange={handleChange}

                            />

                            <ErrorMessage
                              name="first_name"
                              component="div"
                              className="error-message"
                            />

                          </div>
                        </div>
                        <div className="col-50" style={{margin:"12px 0px"}}>
                          <div className="form-group">
                            <label>{configJSON.lastname}</label>

                            <Field

                              type="text"
                              className={this.FormFieldForAdmin(errors.last_name, touched.last_name, values.last_name)}
                              name="last_name"
                              id="last_name"
                              onChange={handleChange}
                            />

                            <ErrorMessage
                              name="last_name"
                              component="div"
                              className="error-message"
                            />

                          </div>
                        </div>
                        <div className="col-50" style={{margin:"12px 0px"}}>
                          <div className="form-group">
                            <label>{configJSON.preferredPronoun}</label>

                            <Field
                              type="text"
                              className={this.FormFieldForAdmin(errors.preferred_pronounce, touched.preferred_pronounce, values.preferred_pronounce)}
                              name="preferred_pronounce"
                              id="preferred_pronounce"
                              onChange={handleChange}
                            />

                            <ErrorMessage
                              name="preferred_pronounce"
                              component="div"
                              className="error-message"
                            />

                          </div>
                        </div>
                        <div className="col-50" style={{margin:"12px 0px"}}>
                          <div className="form-group">

                            <div className="field-wrapper">
                              <div>
                                <img src={contactInfo} alt="logo" width={25} height={25} />
                              </div>
                              <div>
                                <label>{configJSON.mobileNumber}</label>
                              </div>
                            </div>

                            <div className="flag-with-input">
                              <div className="flag-input-field" id="phoneInput">

                                <PhoneInput
                                  country={1}
                                  inputProps={{
                                    name: 'full_phone_number',

                                  }}
                                  enableSearch={true}
                                  data-test-id="phoneInput"
                                  value={values.full_phone_number}
                                  onChange={(phone: any, countryData: any) => { this.handlePhoneNumberChange(phone, countryData, handleChange, setFieldValue) }}
                                />
                                <ErrorMessage
                                  name="full_phone_number"
                                  component="div"
                                  className="error-message"
                                />
                              </div>


                            </div>

                          </div>
                        </div>
                        <div className="col-50" style={{margin:"12px 0px"}}>
                          <div className="form-group">
                            <label>{configJSON.hometown}</label>

                            <Field

                              type="text"
                              className={this.FormFieldForAdmin(errors.home_town, touched.home_town, values.home_town)}
                              name="home_town"
                              id="home_town"
                              onChange={handleChange}

                            />

                            <ErrorMessage
                              name="home_town"
                              component="div"
                              className="error-message"
                            />

                          </div>
                        </div>
                        <div className="col-50" style={{margin:"12px 0px"}}>
                          <div className="form-group">
                            <label>{configJSON.address}</label>
                            <Field
                              type="text"
                              className={this.FormFieldForAdmin(errors.address, touched.address, values.address)}
                              name="address"
                              id="address"
                              onChange={handleChange}

                            />

                            <ErrorMessage
                              name="address"
                              component="div"
                              className="error-message"
                            />

                          </div>
                        </div>
                        <div className="col-50" style={{margin:"12px 0px"}}>
                          <div className="form-group" id="bloodGroup">
                            <label>{configJSON.bloodgroup}</label>
                            <Field
                              as="select"
                              data-testid="bloodGroup"
                              className={this.FormFieldForAdmin(errors.blood_group, touched.blood_group, values.blood_group)}
                              name="blood_group"
                              id="blood_group"
                              onChange={handleChange}
                            >
                              <option id="a+" value="A+">{configJSON.ap}</option>
                              <option id="a-" value="A-">{configJSON.an}</option>
                              <option id="b+" value="B+">{configJSON.bp}</option>
                              <option id="b-" value="B-">{configJSON.bn}</option>
                              <option id="ab+" value="AB+">{configJSON.abp}</option>
                              <option id="ab-" value="AB-">{configJSON.abn}</option>
                              <option id="o+" value="O+">{configJSON.op}</option>
                              <option id="o-" value="O-">{configJSON.on}</option>
                            </Field>
                            <ErrorMessage
                              name="blood_group"
                              component="div"
                              className="error-message"
                            />


                          </div>
                        </div>
                        <div className="col-50" style={{margin:"12px 0px"}}>
                          <div className="form-group">
                            <label>{configJSON.dateOfBirth}</label>
                            <Field
                              type="date"
                              className={this.FormFieldForAdmin(errors.date_of_birth, touched.date_of_birth, values.date_of_birth)}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleChange(e);
                              }}
                              name="date_of_birth"
                              id="date_of_birth"
                            />
                            <ErrorMessage
                              name="date_of_birth"
                              component="div"
                              className="error-message"
                            />

                          </div>
                        </div>
                        <div className="col-50" style={{margin:"12px 0px"}}>
                          <div className="form-group" data-testId="aboutEmployee">
                            <label>{configJSON.briefAboutEmployee}</label>
                            <textarea
                              rows={4}
                              className={this.FormFieldForAdmin(errors.brief_about_employee, touched.brief_about_employee, values.brief_about_employee)}
                              name="brief_about_employee"
                              id="brief_about_employee"
                              onChange={handleChange}
                              value={values.brief_about_employee}
                            />

                            <ErrorMessage
                              name="brief_about_employee"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-50" style={{margin:"12px 0px"}}>
                          <div className="form-group" id="aboutExpertie">
                            <label>{configJSON.askEmployee}</label>
                            <textarea
                              rows={4}
                              data-testId="aboutExpertie"
                              className={this.FormFieldForAdmin(errors.ask_employee_about_expertise, touched.ask_employee_about_expertise, values.ask_employee_about_expertise)}
                              name="ask_employee_about_expertise"
                              id="ask_employee_about_expertise"
                              onChange={handleChange}
                              value={values.ask_employee_about_expertise}
                            />

                            <ErrorMessage
                              name="ask_employee_about_expertise"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-50" style={{margin:"12px 0px"}}>
                          <div className="form-group" id="higherEducation">
                            <label>{configJSON.highestEducation}</label>
                            <Field
                              type="text"
                              className={this.FormFieldForAdmin(errors.higher_education, touched.higher_education, values.higher_education)}
                              name="higher_education"
                              id="higher_education"
                              onChange={handleChange}

                            />

                            <ErrorMessage
                              name="higher_education"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-field text-center" id="signupBlock4">
                      <div className="control" id="signupfieldcontrol2">
                        <button
                          type="submit"
                          style={webStyle.modalBtn}
                          data-testid="formSubmitBtn"
                          name="save"
                          className="input-submit"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </Modal>
        </div>
      </div>


      // Customizable Area End
    );
  }
}
const webStyle = {
  modalContainer: {
    top: "50%",
    left: "50%",
    transform: `translate(-${"50%"}, -${"50%"})`, // Adjust the transform to center the modal
    position: "absolute",
    width: 750,
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px",
    padding: "32px",
    borderRadius: "10px",
    overflowY: "scroll",
    height: "100vh"
  },
  modalBtn: {
    width: "181px",
    height: "50px",
    backgroundColor: "#FFCC00",
    borderRadius: "5px",
    boxShadow: "none",
    border: "none",
    marginBottom: "10px"
  },
} as any;