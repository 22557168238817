import React from "react";

import {
  // Customizable Area Start
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Grid,
  Paper,
  Modal,
  Box
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import clsx from "clsx";
import "../../dashboard/src/dashboard.css"
import { Link } from "react-router-dom"
import './SetUserProfile.css';
import { close_square, profile_edit_pencil_icon , profile_add_icon, user_profile_icon } from './assets'




// Customizable Area End

import SetUserPrifileController, {
  Props,
  configJSON
} from "./SetUserProfileController";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export default class CustomisableUserProfiles extends SetUserPrifileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getProfileSreen = () => {
    const { attributes: { employee_id = '', role = "", country = "", full_phone_number = "", email = "", timings = "", time_zone = "", state = "", first_name = "", last_name = '', department_name = "", reporting_to = "", date_of_joining = "", company_name = "", city = "", designation = "", employee_type = "" } = {} } = this.state.editAdminFormData
    const getOrDefault = (value: any) => {
      return (value !== null && value !== "") ? value : 'NA';
    };
    return (
      <>
        <Box style={webStyle.detailsMainContainer}>



          <Paper style={webStyle.detailsContainer}>
            <span style={webStyle.cardHeader}>Personal info</span>
            <Box style={webStyle.cardContentmainWrapper}>

              <Box style={webStyle.cardFirstContentmain}>
                <span style={webStyle.cardFirstContent}>First Name</span>
                <span style={webStyle.cardSecondContent}>{getOrDefault(first_name)}</span>
              </Box>
              <Box style={webStyle.cardFirstContentmain}>
                <span style={webStyle.cardFirstContent}>Last Name</span>
                <span style={webStyle.cardSecondContent}>{getOrDefault(last_name)}</span>
              </Box>
                <Box style={webStyle.cardFirstContentmain}>
                <span style={webStyle.cardFirstContent}>Email Id</span>
                <span style={webStyle.cardSecondContent}>{getOrDefault(email)}</span>
              </Box>
            </Box>

          </Paper>

          <Paper style={webStyle.detailsContainerLong}>
            <span style={webStyle.cardHeader}>Work</span>
            <div style={{ display: 'flex' }}>
              <Grid container justifyContent="center" style={{ width: "50%" }}>
                <Grid item xs={8} direction="column" style={webStyle.cardContentmain}>
                  <span style={webStyle.cardContent}>Employee Id</span>
                  <span style={webStyle.cardContent}>Work Phone</span>
                  <span style={webStyle.cardContent}>Department</span>
                  <span style={webStyle.cardContent}>Designation</span>
                  <span style={webStyle.cardContent}>Role</span>
                  <span style={webStyle.cardContent}>Reporting To</span>
                  <span style={webStyle.cardContent}>Staffing Location (Country)</span>
                </Grid>
                <Grid item xs={4} direction="column" style={webStyle.cardContentmain}>
                  <span style={webStyle.cardContent}>{getOrDefault(employee_id)}</span>
                  <span style={webStyle.cardContent}>{getOrDefault(full_phone_number)}</span>
                  <span style={webStyle.cardContent}>{getOrDefault(department_name)}</span>
                  <span style={webStyle.cardContent}>{getOrDefault(designation)}</span>
                  <span style={webStyle.cardContent}>{getOrDefault(role)}</span>
                  <span style={webStyle.cardContent}>{getOrDefault(reporting_to)}</span>
                  <span style={webStyle.cardContent}>{getOrDefault(country)}</span>
                </Grid>
              </Grid>
              {/* <div style={webStyle.divider} /> */}
              <Grid container justifyContent="center" className="divider-box" style={{ width: "50%", marginLeft: '80px' }}>
                <Grid item xs={8} direction="column" style={webStyle.cardContentmain}>
                  <span style={webStyle.cardContent}>Staffing Location (State)</span>
                  <span style={webStyle.cardContent}>Staffing Location (City)</span>
                  <span style={webStyle.cardContent}>Date Of Joinning</span>
                  <span style={webStyle.cardContent}>Employee Type</span>
                  <span style={webStyle.cardContent}>Company Name</span>
                  <span style={webStyle.cardContent}>Time Zone</span>
                  <span style={webStyle.cardContent}>Shifting Times</span>
                </Grid>
                <Grid item xs={4} direction="column" style={webStyle.cardContentmain}>
                  <span style={webStyle.cardContent}>{getOrDefault(state)}</span>
                  <span style={webStyle.cardContent}>{getOrDefault(city)}</span>
                  <span style={webStyle.cardContent}>{getOrDefault(date_of_joining)}</span>
                  <span style={webStyle.cardContent}>{getOrDefault(employee_type)}</span>
                  <span style={webStyle.cardContent}>{getOrDefault(company_name)}</span>
                  <span style={webStyle.cardContent}>{getOrDefault(time_zone)}</span>
                  <span style={webStyle.cardContent}>{getOrDefault(timings)}</span>
                </Grid>
              </Grid>
            </div>
          </Paper>


        </Box>
        <div className="form-field text-center" id="signupBlock4">
          <div className="control" id="signupfieldcontrol2">
            <button type="button" data-test-id="btnEmailLogIn"
              style={webStyle.cotinueBtn}
              onClick={this.handleGotoSetProfile}
              className="button submit">
              Confirm
            </button>
          </div>
        </div>
      </>
    )
  }
  setProfileScreen = () => (
    <form>
      <Grid container style={webStyle.detailsMainContainer1} xs={12}>
        <Grid item xs={6}>
          <div className="profile-icon">
            <a href="#"
              data-test-id="avatarIcon"
              className="user-profile"
              onClick={this.handleOpenModal}
            >
              {this.state.profile_img !== "" ?
                <div style={webStyle.iconContainer}>
                  <span className="pencil-icon">
                    <img src={profile_edit_pencil_icon} alt="pencil" style={{ height: "24px", width: "24px" }} />
                  </span>
                  <img src={this.state.profile_img} alt="" style={webStyle.profileImage} />
                </div>
                :
                <div style={webStyle.iconContainer}>
                  <img src={user_profile_icon} />
                  <span className="pencil-icon">
                    <img src={profile_add_icon} alt="pencil" style={{ height: "24px", width: "24px" }} />
                    </span>
                </div>
              }
            </a>
            <input
              data-test-id="txtInputProfile"
              type="file"
              ref={this.fileInputRef}
              accept="image/*"
              style={{ display: 'none' }}
              onChange={this.handleImageUpload}
            />
          </div>
        </Grid>
        <Box style={webStyle.detailsMainContainer2}>
          <Grid item xs={6}>
            <div className="form-field" id="signupform">
              <span style={webStyle.labelStyle}>Preferred Pronounce</span>
              <div className="control" id="signupfieldcontrol" style={webStyle.marginLeft0}>
                <input type="text"
                  data-test-id="txtInputPronounce"
                  defaultValue={this.state.pronounce}
                  onChange={(e) => {
                    this.setState({ pronounce: e.target.value })
                  }}
                  style={this.state.pronounce && webStyle.inputField || {}}
                  className="control-input user-control-input"
                  placeholder="Enter pronounce"
                  name="pronounce"
                  id="pronounce-control" />
              </div>
            </div>
            <div className="form-field phone-input" id="signupform">
              <span style={webStyle.labelStyle}>Personal Phone Number</span>
              <div className="control Personal" id="signupfieldcontrol" style={webStyle.marginLeft0}>
                <PhoneInput
                  country={"in"}
                  inputProps={{
                    name: 'full_phone_number',
                  }}
                  inputStyle ={{padding:"15px 90px !important", height:"60px"}}
                  enableSearch={true}
                  data-test-id="txtphoneInput"
                  value={this.state.full_phone_number}
                  onChange={(phone: any, countryData: any) => {
                    this.handlePhoneNumberChange(phone, countryData)
                  }}
                />
              </div>
            </div>
            <div className="form-field" id="signupform">
              <span style={webStyle.labelStyle}>Home Town</span>
              <div className="control" id="signupfieldcontrol" style={webStyle.marginLeft0}>
                <input type="text"
                  data-test-id="txtInputHometown"
                  defaultValue={this.state.homeTown}
                  onChange={(e) => {
                    this.setState({ homeTown: e.target.value })
                  }}
                  className="control-input user-control-input"
                  style={this.state.pronounce && webStyle.inputField || {}}
                  placeholder="Enter Your Home Town"
                  name="homeTown"
                  id="homeTown-control" />
              </div>
            </div>
            <div className="form-field" id="signupform">
              <span style={webStyle.labelStyle}>Address</span>
              <div className="control" id="signupfieldcontrol" style={webStyle.marginLeft0}>
                <input type="text"
                  data-test-id="txtInputAddress"
                  defaultValue={this.state.address}
                  onChange={(e) => {
                    this.setState({ address: e.target.value })
                  }}
                  className="control-input user-control-input"
                  style={this.state.pronounce && webStyle.inputField || {}}
                  placeholder="Enter Your Address"
                  name="address"
                  id="address-control" />
              </div>
            </div>
            <div className="form-field" id="signupform">
              <span style={webStyle.labelStyle}>Blood Group</span>
              <div className={`control`} id="signupfieldcontrol" style={webStyle.marginLeft0}>
                <select
                  data-testid="bloodGroup"
                  name="blood_group"
                  id="blood_group"
                  value={this.state.bloodGroup}
                  style={this.state.pronounce && webStyle.inputField || {}}
                  onChange={(e) => {
                    this.setState({ bloodGroup: e.target.value });
                  }}

                >
                  <option value="">Select</option>
                  <option id="a+" value="A+">{configJSON.ap}</option>
                  <option id="a-" value="A-">{configJSON.an}</option>
                  <option id="b+" value="B+">{configJSON.bp}</option>
                  <option id="b-" value="B-">{configJSON.bn}</option>
                  <option id="ab+" value="AB+">{configJSON.abp}</option>
                  <option id="ab-" value="AB-">{configJSON.abn}</option>
                  <option id="o+" value="O+">{configJSON.op}</option>
                  <option id="o-" value="O-">{configJSON.on}</option>
                </select>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="form-field" id="signupform">
              <span style={webStyle.labelStyle}>Higher Education</span>
              <div className="control" id="signupfieldcontrol" style={webStyle.marginLeft0}>
                <input type="text"
                  data-test-id="txtInputEducation"
                  defaultValue={this.state.higher_education}
                  onChange={(e) => {
                    this.setState({ higher_education: e.target.value })
                  }}
                  style={this.state.pronounce && webStyle.inputField || {}}
                  className="control-input user-control-input"
                  placeholder="Enter Your Education"
                  name="higher_education"
                  id="higher_education-control" />
              </div>
            </div>
            <div className="form-field" id="signupform">
              <span style={webStyle.labelStyle}>Brief about Me</span>
              <div className="control" id="signupfieldcontrol" style={webStyle.marginLeft0}>
                <textarea
                  data-test-id="txtInputAboutMe"
                  defaultValue={this.state.brief_about_employee}
                  onChange={(e) => {
                    this.setState({ brief_about_employee: e.target.value })
                  }}
                  style={this.state.pronounce && webStyle.inputField || {}}
                  rows={5}
                  className="control-input user-control-input"
                  placeholder="Type something here..."
                  name="brief_about_employee"
                  id="brief_about_employee-control" />
              </div>
            </div>
            <div className="form-field" id="signupform">
              <span style={webStyle.labelStyle}>Ask me about or Experties</span>
              <div className="control" id="signupfieldcontrol" style={webStyle.marginLeft0}>
                <textarea
                  data-test-id="txtInputExpertise"
                  defaultValue={this.state.ask_employee_about_expertise}
                  onChange={(e) => {
                    this.setState({ ask_employee_about_expertise: e.target.value })
                  }}
                  rows={3}
                  style={this.state.pronounce && webStyle.inputField || {}}
                  className="control-input user-control-input"
                  placeholder="Type something here..."
                  name="expertise"
                  id="expertise-control" />
              </div>
            </div>
          </Grid>
        </Box>
      </Grid>
      <div className="form-field text-center" id="signupBlock4">
        <div className="control" id="signupfieldcontrol2">
          <button type="button" data-test-id="btnEmailLogIn"
            style={(this.state.pronounce && this.state.higher_education && this.state.full_phone_number && this.state.brief_about_employee && this.state.homeTown && this.state.address && this.state.ask_employee_about_expertise && this.state.bloodGroup && this.state.profile_img) ? webStyle.cotinueBtn : webStyle.disableCotinueBtn}
            onClick={this.handleAdminFormSubmit}
            disabled={(this.state.pronounce && this.state.higher_education && this.state.full_phone_number && this.state.brief_about_employee && this.state.homeTown && this.state.address && this.state.ask_employee_about_expertise && this.state.bloodGroup && this.state.profile_img)?false:true}
            className="button submit">
            Save
          </button>
        </div>
      </div>
    </form>
  )
  // Customizable Area End

  render() {
    const { activeStep } = this.state;

    return (
      // Customizable Area Start
      <div style={webStyle.parentDiv} className="steper-wrapper" id="signupparent">
        <div id="signupBlock" style={webStyle.subDiv} >
          <div id="signupBlock1" className="center-block-header text-center stepper-container">
            <div style={webStyle.headerContainer}>
              <h3>Just a few steps to go...</h3>
              {this.state.screen === 1 && <Link to="/" style={webStyle.linkText}>Skip</Link>}
            </div>
            <Stepper style={webStyle.stepperRoot} activeStep={activeStep} connector={<StepConnector style={webStyle.connector} className={`${this.state.screen === 1 && 'second-step-active'}`} />}>
              {[1, 2].map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    style={webStyle.steplabel}
                    StepIconComponent={({ active, completed }) => (
                      <div
                        className={clsx({
                          active: active,
                          completed: completed,
                        })}
                        style={{
                          color: "#000", // Black font color
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: this.getBackgroundColor(active, completed), 
                          borderRadius: "50%",
                          padding: "6px 12px",
                          border: this.getBorderColor(active, completed), 
                        }}
                      >
                        {index + 1}
                      </div>
                    )}
                  />
                </Step>
              ))}
            </Stepper>
            <p style={webStyle.subHeadingStyle} >{this.state.subHeading}</p>
          </div>
          <div className="form-block user-profile-setup" id="signupBlock3">
            {this.state.screen === 0 && this.getProfileSreen()}
            {this.state.screen === 1 && this.setProfileScreen()}
            <Modal
              open={this.state.openModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div style={webStyle.modalContainer} id="modal" className="center-block-header text-center">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "first baseline" }}>
                  <h3>Add profile image</h3>
                  <img src={close_square} alt="close_square" style={{cursor : 'pointer'}} onClick={() => this.setState({ openModal: false })} data-test-id="modalcanel" />
                </div>
                <span style={{ color: "black", cursor: "pointer" }} onClick={this.handleProfileImageClick}>Choose from local drive</span>
                <span style={{ color: "black", cursor: "pointer" }} onClick={() => this.setState({ profile_img: "", profile: "", openModal: false })}>Remove image</span>

              </div>
            </Modal>
          </div>
        </div>

      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  parentDiv: {
    position: "relative",
    minHeight: "100vh",
    padding: '60px 0',
    overflow: 'auto'
  },
  subDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  subHeadingStyle: {
    fontSize: '20px',
    lineHeight: '22px',
    color: '#000',
    marginBottom: '0'
  },
  detailsContainer: {
    border: "1px solid #0000003d",
    padding: "40px",
    borderRadius: '8px',
    maxWidth: '378px',
    width: '100%',
    height: 'inherit'
  },
  detailsContainerLong: {
    border: "1px solid #0000003d",
    padding: "40px",
    borderRadius: '8px',
    maxWidth: '915px',
    width: '100%',
    height: '100%'
  },
  detailsMainContainer: {
    gap: '20px',
    display: 'flex',
    justifyContent: "center",
    marginTop: '36px'
  },
  detailsMainContainer1: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  detailsMainContainer2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: '20px',
    columnGap: '100px',
  },
  cotinueBtn: {
    margin: '10px 0px 0px',
    width: '385px',
    fontSize: '16px',
    color: "#000",
    lineHeight: '20px',
    padding: '18px'
  },
  disableCotinueBtn:{
    margin: '10px 0px 0px',
    width: '385px',
    fontSize: '16px',
    color: "#878787",
    lineHeight: '20px',
    padding: '18px',
    backgroundColor:"#F6F6F6",
    cursor: "not-allowed",
    pointerEvents: "none",
    border: "none",
  },
  divider: {
    position: "absolute",
    top: 30,
    bottom: 30,
    left: "50%",
    borderLeft: "0.1px solid #777",
  },
  connector: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: '1px dashed #C8C8C8'
  },
  stepperRoot: {
    paddingLeft: 0, // Remove left padding
    paddingRight: 0, // Remove right padding
  },
  steplabel: {
    padding: 0,
  },
  cardHeader: {
    fontSize: "20px",
    lineHeight: '24px',
    marginBottom: '24px',
    fontWeight: '500',
    display: 'block'
  },
  cardContent: {
    fontSize: "16px",
    lineHeight: '20px',
    color: "#707070"
  },
  cardFirstContent:{
    idth:'40%',
    fontSize: "16px",
    lineHeight: '20px',
    color: "#707070"
  },
  cardSecondContent:{
    width:'60%',
    fontSize: "16px",
    lineHeight: '20px',
    color: "#707070"
  },
  cardContentmainWrapper: {
  },
  cardContentmain: {
    display: "flex",
    columnGap: '60px',
    rowGap: '24px',
    flexDirection: 'column'
  },
  cardFirstContentmain:{
    display:"flex",
    justifyContent:"space-between",
    marginBottom:"15px"
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '720px',
    width: '100%',
    position: 'relative'
  },
  linkText: {
    color: '#6191C7',
    position: 'absolute',
    right: 0,
    top: '6px',
    fontSize: '20px',
    textDecoration: 'none',
    lineHeight: '20px',
    fontWeight: 500
  },
  inputField: {
    border: "1px solid #FDD100",
    background: "none",
  },
  marginLeft0: {
    marginLeft: 0
  },
  labelStyle: {
    color: "black"
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: '100px',
    width: '100px',
    borderRadius: '50%',
    background: '#F5F5F5',
    border: '1px solid #F5F5F5',
    margin: '0'
  },
  profileImage: {
    height: '100px',
    width: '100px',
    borderRadius: '50%',
    background: '#F5F5F5',
    border: '2px solid #FFCC00'
  },
  modalContainer: {
    top: "50%",
    left: "50%",
    transform: `translate(-${"50%"}, -${"50%"})`, // Adjust the transform to center the modal
    position: 'absolute',
    width: '398px',
    height: '198px',
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px',
    padding: '30px',
    borderRadius: "10px",
    textAlign: "start",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },

} as any;
// Customizable Area End

