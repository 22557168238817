import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme , withStyles} from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { version_update, terms_condition, privacy, RightArrow, email_notification } from "./assets";
import { Link } from "react-router-dom"
import Switch from '@material-ui/core/Switch';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SettingsController, {
  Props,
  configJSON,
} from "./SettingsController";


export default class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const accordions = [
      {
        title: 'Email Notification',
        content: 'General Accordion Content',
        icon: <img src={email_notification} alt="logo" width={24} height={24} />,
      },
      {
        title: 'Change Password',
        content: 'Change Password Accordion Content',
        icon: <img src={version_update} alt="logo" width={24} height={24} />,
      },
      {
        title: 'Privacy Policy',
        content: 'Privacy Policy Accordion Content',
        icon: <img src={privacy} alt="logo" width={24} height={24} />,
      },
      {
        title: 'Terms Of Use',
        content: 'Terms Of Use Accordion Content',
        icon: <img src={terms_condition} alt="logo" width={24} height={24} />
      },
    ]
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box style={{ background: "#F3F4F5", height: "64px" }} >
        <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: '40px' }}>
          <Grid className="first" style={{ padding: '19px 0px 21px 38px' }} item>
            <Typography style={{ color: "black", fontSize: "18px", fontWeight: 400 }}>Settings</Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ padding: '0 32px' }}>
          {accordions.map((accordion: any, index: number) => (
            <Grid key={index} className="first" item style={{ width: '100%' }}>

              <Accordion

                expanded={false}
                style={webStyle.accordion}
                className="accordion-wrapper"
              >
                <AccordionSummary
                  expandIcon={
                    accordion.title === 'Email Notification' ? (
                      <AntSwitch
                        data-test-id="emp_switch"
                        checked={this.state.emailNotification}
                        onClick={() =>  this.setState({emailNotification : !this.state.emailNotification}) }
                        name="checked"
                        id={`switch-${index}`}
                      />
                    ) :
                      <Link
                        to={`/${accordion.title}`}>
                        <img src={RightArrow} />
                      </Link>}
                  aria-controls={`panel${index + 1}-content`}
                  id={`panel${index + 1}-header`}
                  className="accordion-border accordion-wrapper"
                >

                  <span style={{ marginRight: '12px' }} className="img-wrapper">

                    {accordion.icon}

                  </span>
                  <Typography style={webStyle.heading as React.CSSProperties}>{accordion.title}</Typography>
                </AccordionSummary>
              </Accordion>

            </Grid>
          ))}
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    fontSize: "18px",
    fontWeight: "500",
    color: '#000'
  },
  accordion: {
    marginBottom: "20px", // Adjust the gap as needed
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Add box shadow
    borderRadius: "12px", // Add border radius
    "&:last-child": {
      marginBottom: 0 // Remove margin bottom for the last accordion
    },
    '& .MuiButtonBase-root': {
      padding: '16px 40px',
      '& .MuiAccordionSummary-content': {
        alignItems: 'center',
        margin: 0
      },
    }
  },

};

const AntSwitch = withStyles(theme => ({
  root: {
    width: 46,
    height: 24,
    padding: 0,
    display: "flex",
    "& .MuiIconButton-label":{
      margin:0
    }
  },
  switchBase: {
    padding: 2,
    // color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(22px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#FDD001",
        borderColor: 'transparent'
      }
    }
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: "none",
    margin:'2px'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 1,
    opacity: 1,
    backgroundColor: "#bcbeca"
  },
  checked: {}
}))(Switch);
// Customizable Area End
