import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from "react-hot-toast";
// Customizable Area End

export const configJSON = require("./config");

export interface Row {
  id: number;
  title: string;
  description: string;
}
export interface CalculationRow {
  id: string;
  min: number;
  max: number;
  weightage:number
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  type: string;
  description: string;
  automatic: boolean;
  runRate: boolean;
  analytics: boolean;
  goaltype: string;
  tableRow:Row[];
  calculationType:CalculationRow[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddDkrDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      type: "prorata",
      description: "",
      automatic: false,
      runRate: false,
      analytics: false,
      goaltype: "number",
      tableRow: [
        { id: 1, title: "Captured DKR", description: "Today's Sales" },
        { id: 2, title: "Gamified DKR", description: "Total Sales" }
      ],
      calculationType:[
        { id: "1", min: 0, max: 90,weightage:2 },
        { id: "2", min: 90.1, max: 100,weightage:8 }

      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start


    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.errors) {
          let message: string;
          const error = responseJson.errors[0];

          if (typeof error === 'string') {
            message = error;
            toast.error(message);
          }
        }
      }
    }
    // Customizable Area End
  }

  // web events
  handleChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: string;
  }>) => {
    this.setState({
      type: event.target.value
    });
  };
  handleAddItem = () => {
    const newItem = { id: '', min: 0, max: 0, weightage: 0 }; // You can adjust the initial values as needed
    this.setState(prevState => ({
      calculationType: [...prevState.calculationType, newItem]
    }));
  };

  handleRemoveItem = (index:number) => {
    this.setState(prevState => ({
      calculationType: prevState.calculationType.filter((_, i) => i !== index)
    }));
  };
 
  // Customizable Area Start
  // Customizable Area End
}
