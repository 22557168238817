import React from "react";
import DashboardController, { Props } from "./DashboardController";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { theme } from "./Template.web";
import "./dashboard.css";
import { profile } from "./assets";



  

export default class Department extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
   
    return(
        <>
        <div className="content-wrapper">
            <div className="department-header">
                <h2>Departments</h2>
            </div>
        <div style={webStyle.root}>
           
      <Accordion style={webStyle.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordion-border"
        >
          <Typography style={webStyle.heading as React.CSSProperties}>Development</Typography>
        </AccordionSummary>
       
        <Accordion className="sub-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="sub-panel1a-content"
                id="sub-panel1a-header"
              >
                <Typography >Senior Manager</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <span className="profile-img">{<img  src={profile} alt="logo" width={16} height={20}/>}</span>
                <Typography>
                  Anna Berk
                </Typography>
                </AccordionDetails>
                <AccordionDetails>
                <span className="profile-img">{<img  src={profile} alt="logo" width={16} height={20}/>}</span>
                <Typography>
                  Bill Soit
                </Typography>
                </AccordionDetails>
                <AccordionDetails>
                <span className="profile-img">{<img  src={profile} alt="logo" width={16} height={20}/>}</span>
                <Typography>
                  Joseph Sills
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="sub-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="sub-panel1a-content"
                id="sub-panel1a-header"
              >
                <Typography >Product Manager</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <span className="profile-img">{<img  src={profile} alt="logo" width={16} height={20}/>}</span>
                <Typography>
                  Anna Berk
                </Typography>
                </AccordionDetails>
                <AccordionDetails>
                <span className="profile-img">{<img  src={profile} alt="logo" width={16} height={20}/>}</span>
                <Typography>
                  Bill Soit
                </Typography>
                </AccordionDetails>
                <AccordionDetails>
                <span className="profile-img">{<img  src={profile} alt="logo" width={16} height={20}/>}</span>
                <Typography>
                  Joseph Sills
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="sub-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="sub-panel1a-content"
                id="sub-panel1a-header"
              >
                <Typography >Junior Manager</Typography>
              </AccordionSummary>
             
            </Accordion>
            <Accordion className="sub-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="sub-panel1a-content"
                id="sub-panel1a-header"
              >
                <Typography >Front-end Developer</Typography>
              </AccordionSummary>
             
            </Accordion>
            <Accordion className="sub-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="sub-panel1a-content"
                id="sub-panel1a-header"
              >
                <Typography >Intern</Typography>
              </AccordionSummary>
             
            </Accordion>
      
      </Accordion>
      <Accordion style={webStyle.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordion-border"
        >
          <Typography style={webStyle.heading as React.CSSProperties}>Design</Typography>
        </AccordionSummary>
      </Accordion>
      <Accordion style={webStyle.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordion-border"
        >
          <Typography style={webStyle.heading as React.CSSProperties}>Sales</Typography>
        </AccordionSummary>
      </Accordion>
      <Accordion style={webStyle.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordion-border"
        >
          <Typography style={webStyle.heading as React.CSSProperties}>Procurement</Typography>
        </AccordionSummary>
      </Accordion>
      <Accordion style={webStyle.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordion-border"
        >
          <Typography style={webStyle.heading as React.CSSProperties}>Human Resources</Typography>
        </AccordionSummary>
      </Accordion>
      
      
      
    </div>
        </div>
        </>
    )
  }
}

const webStyle={
    root: {
        width: '100%',
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "bold",
      },
      accordion: {
        marginBottom: "10px", // Adjust the gap as needed
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Add box shadow
        borderRadius: "10px", // Add border radius
        padding:"0px 10px 0px 0px",
        "&:last-child": {
          marginBottom: 0 // Remove margin bottom for the last accordion
        }
      },
}