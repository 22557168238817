import React from "react";
// Customizable Area Start
import {
  Button,
  Menu,
  Typography,
  Box,
  IconButton,
  Modal,
  TextField,
  MenuItem,Link,} from "@material-ui/core";
  import {MoreVert} from "@material-ui/icons";
  import { createTheme } from "@material-ui/core/styles";
  import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
  import Breadcrumbs from '@material-ui/core/Breadcrumbs';


// Customizable Area End
import DashboardController, { Props } from "./DashboardController"; 
export const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#fff",
    }
  },
  typography: {
    subtitle1: {
      margin: "20px 0px"
    },
    h6: {
      fontWeight: 500
    },
  }
});
export default class EditTemplate2 extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  render() { 
    // Customizable Area Start
    
      const AddKpi2 = () => {
        const { kpiName, kpiDescription, weightage, goalCalculation } = this.state;
        const open = Boolean(this.state.anchorEl);
        const allFieldsFilled = kpiName && kpiDescription && weightage && goalCalculation;
      
          return(
          <Box
            style={{
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              backgroundColor: 'white',
              borderRadius: "12px",
              display: 'flex',
              flexDirection: 'row',
              padding: "30px 15px",
              marginLeft: '20px',
              width: 'fit-content',
              marginTop: '50px',
            }}
          >
            <Box style={{ marginLeft: '20px' }}>
              <Typography
                style={{
                  fontSize: "18px",
                  color: 'black',
                  fontFamily: 'Open Sans',
                  fontWeight: 700,
                }}
              >
                KPI Name
              </Typography>
              <TextField
              data-test-id={`feature`}
              value={kpiName}
              hiddenLabel
                InputProps={{
                  disableUnderline: true,
                }}
                style={{
                  backgroundColor: '#F3F4F5',
                  border: 'none',
                  width: '150px',
                  marginTop: '10px',
                  padding: '8px',
                  borderRadius: '10px',
                }}
              />
            </Box>
            <Box style={{ marginLeft: '15px' }}>
              <Typography
                style={{
                  color: 'black',
                  fontFamily: 'Open Sans',
                  fontSize: "18px",
                  fontWeight: 700,
                }}
              >
                KPI Description
              </Typography>
              <TextField
                value={kpiDescription}
                data-test-id={`description`}
                rows={6}
                multiline
                fullWidth
                InputProps={{
                  disableUnderline: true,
                }}
                style={{
                  width: '330px',
                  marginTop: '10px',
                  borderRadius: '10px',
                  border: 'none',
                  padding: '8px',
                  backgroundColor: '#F3F4F5',
                }}
              />
            </Box>
    
            <Box style={{ marginLeft: '15px' }}>
              <Typography
                style={{
                  color: 'black',
                  fontWeight: 700,
                  fontFamily: 'Open Sans',
                  fontSize: "18px",
                }}
              >
                Weightage
              </Typography>
              <TextField
              data-test-id={`description`}
              value={weightage}
              hiddenLabel
                InputProps={{
                  disableUnderline: true,
                }}
                style={{
                  backgroundColor: '#F3F4F5',
                  border: 'none',
                  width: '130px',
                  borderRadius: '10px',
                  padding: '8px',
                  marginTop: '10px',
                }}
              />
            </Box>
    
            <Box style={{ marginLeft: '15px' }}>
              <Typography
                style={{
                  fontWeight: 700,
                  fontFamily: 'Open Sans',
                  fontSize: "18px",
                  color: 'black',
                }}
              >
                Goal Calculation
              </Typography>
              <TextField
              data-test-id={`description`}
              value={goalCalculation}
                hiddenLabel
                InputProps={{
                  disableUnderline: true,
                }}
                style={{
                  border: 'none',
                  borderRadius: '10px',
                  backgroundColor: '#F3F4F5',
                  width: '200px',
                  marginTop: '10px',
                  padding: '8px',
                }}
              />
            </Box>
    
            <Box style={{ marginLeft: '15px', marginTop: '30px' }}>
              {allFieldsFilled ? (
                <Button
                data-testid="addKpi"
                  style={{
                    borderRadius: "13px",
                    padding: "11px",
                    backgroundColor: "#d8eed6",
                    width: "130px",
                    color: "#1fa625",
                    fontSize: "16px",
                    textTransform: "none",
                    fontWeight: 250,
                    border: "1px solid #88cb85",
                  }}
                >
                  Ready
                </Button>
              ) : (
                <Button
                data-testid="addKpi"
                  style={{
                    borderRadius: "13px",
                    padding: "11px",
                    backgroundColor: "#dcdfff",
                    color: "#7d7dde",
                    fontSize: "16px",
                    width: "130px",
                    fontWeight: 400,
                    border: "1px solid #a5a8ff",
                    textTransform: "none",
                  }}
                >
                  Preparing
                </Button>
              )}
            </Box>
            <Box>
              <IconButton data-testid="addKpi" onClick={this.handleClick}>
                <MoreVert style={{ color: 'black' }} />
              </IconButton>
              <Menu
                anchorEl={this.state.anchorEl}
                open={open}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                style: {
                padding:"10px",
                left: "10px",
                top: "370px",
              }
           }}
       >
      <MenuItem 
        style={{ backgroundColor: '#FDD001', color: 'black' }} 
        onClick={this.toggleModal}   
      >
        View Detailed KPI{}
      </MenuItem>
      <MenuItem     
            >
              Edit KPI Details
            </MenuItem>
      <MenuItem 
        style={{  color: 'black' }}
        
      >
        Add DKR Details
      </MenuItem>
      <MenuItem 
        style={{  color: 'black' }}
      >
        Remove
      </MenuItem>
    </Menu>
    {UpdateKpiDetailCard()}
          </Box>
            </Box>
          )
         } 
         const  UpdateKpiDetailCard =() => {
    
            return(
              <Modal
              aria-describedby="email-verified-description"
              open={this.state.isduplicateOpen}
              style={{opacity:0.5}}
                aria-labelledby="email-verified-title"
              >
                <Box style={{
                  top: '50%',
                  position: 'absolute',
                 left: '50%',
                 width: 490,
                 transform: 'translate(-50%, -50%)',
                 boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                 backgroundColor: 'white',
                 borderRadius: "8px",
                 padding: "30px",
                
                }}>
                 
                 <Box style={{display:"flex", justifyContent:"space-between",flexDirection: "column" ,}}>
                  <Box style={{display:"flex",flexDirection: "column", justifyContent:"center"}}>
                    <Typography style={{color:"black",fontSize: 22,fontWeight:150,display:"flex",justifyContent:"center",}}>
                    Do you really want to update KPI Details?
                    </Typography>
                    <Typography style={{ fontSize: 18,lineHeight:2.5,color: 'black',display:"flex",justifyContent:"center",}}>Updating KPI Details will remove the DKRs</Typography>  
                  </Box>
                  <Box style={{display:"flex", flexDirection:"row",justifyContent:"center",paddingLeft:"40px",paddingRight:"40px",marginTop: "22px"}}>
                     <Button data-testid="addKpi" style={{color:"black",marginRight: "12px",width: "80px",fontSize: "small",backgroundColor:"FDD100",fontWeight:250}} onClick={this.navigateToSignUp.bind(this)} >
                      Yes
                     </Button>
                     <Button data-testi-d="handleclose2" variant="outlined" style={{backgroundColor:"white",fontWeight:250,color:"black",width: "80px",fontSize: "small",}} onClick={() => {this.handleClose()}}>
                      No
                     </Button>
                  </Box>
                </Box>
                  </Box>   
              </Modal>
            )
          };
          const renderKpi = (kpi:any) => (
            <Box key={kpi}>
              <Box>{AddKpi2()}</Box>
            </Box>
          );
          const breadcrumbs = [
            <Link
              key="1"
              href="/EditTemplate2"
              // onClick={this.handleClick}
            >
              <Typography style={{color: 'black', fontSize: "18px", fontWeight: 600 ,fontFamily: 'Open Sans',}}>Edit Template</Typography>
            </Link>,
          ];
    // Customizable Area End
    return (
      // Customizable Area Start
      <> 
      <form style={webStyle.template as React.CSSProperties}>
              <div style={webStyle.filterHeader}>
              <Breadcrumbs separator="" aria-label="breadcrumb">
              <div>
                  <IconButton style={{color:"black" , fontSize: "small", padding: "0px",background:"none" }} onClick={() => {this.navigateToTemplateAdded()}}>
                     <ArrowBackIosIcon/>
                  </IconButton>
                </div>
               {breadcrumbs}
              </Breadcrumbs>
              </div>
            </form>
       <Box>
        <Box sx={webStyle.templateNameContainer}>
          <Box style={{ marginLeft: '20px' }}>
            <Typography style={webStyle.templateNameText}>
              Template Name
            </Typography>
            <TextField
              hiddenLabel
              data-test-id={`description`}
              value=""
              style={webStyle.templateNameInput}
              InputProps={{ disableUnderline: true }}
            />
          </Box>
          <Box style={{ marginLeft: '15px' }}>
            <Typography style={webStyle.templateNameText}>
              Template Description
            </Typography>
            <TextField
              data-test-id={`description`}
              value=""
              rows={6}
              multiline
              InputProps={{ disableUnderline: true }}
              fullWidth
              style={webStyle.descriptionInput}
            />
          </Box>
        </Box>

        <Box sx={webStyle.kpiHeader}>
          <Typography style={webStyle.kpiListText}>
            KPI List
          </Typography>
          <Button
            data-testid="addKpi"
            style={webStyle.addKpiButton}
            onClick={this.addKpi}
          >
            Add KPI
          </Button>
        </Box>

        <Box sx={webStyle.totalWeightageContainer}>
          <Box>
            <Box style={webStyle.weightageCircle}>
              <Typography style={webStyle.weightageText}>
                100%
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography style={webStyle.totalWeightageText}>
              Total Weightage
            </Typography>
            <p style={webStyle.weightageDescription}>
              This Weightage Is The Total Sum Of All The KPIS Available In A Template, And Is Affected On Addition And Reduction Of KPI. This Total Always Needs To Be 100.
            </p>
            <p style={webStyle.weightageNote}>
              Total Always Needs To Be 100.
            </p>
          </Box>
        </Box>

        <Box data-testid="addKpi">
          <Typography style={webStyle.coreText}>
            Core
          </Typography>
        </Box>

        <Box>
          {AddKpi2()}
          {AddKpi2()}
          {AddKpi2()}
          {AddKpi2()}
          {this.state.kpis.map(renderKpi)} 
        </Box>

        <Box style={{ marginTop: '50px', 
        marginLeft: '20px' }}>
          <Button data-testid="addKpi" style={webStyle.submitButton}>
            Submit
          </Button>
          <Button variant="outlined" style={webStyle.cancelButton}>
            Cancel
          </Button>
        </Box>
      </Box>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  templateNameContainer: {
    flexDirection: 'row',
    display: 'flex',
    marginTop: '20px',
  },
  templateNameText: {
    fontWeight: 700,
    fontFamily: 'Open Sans',
    color: 'black',
    fontSize: "18px",
  },
  templateNameInput: {
    backgroundColor: '#F3F4F5',
    border: 'none',
    width: '450px',
    borderRadius: '10px',
    padding: '8px',
    marginTop: '15px',
  },
  descriptionInput: {
    width: '550px',
    marginTop: '15px',
    border: 'none',
    backgroundColor: '#F3F4F5',
    borderRadius: '10px',
    padding: '8px',
  },
  kpiHeader: {
    display: "flex",
    height: "70px",
    flexDirection: "row",
    backgroundColor: '#F3F4F5',
    justifyContent: "space-between",
    marginTop: '20px',
  },
  kpiListText: {
    color: 'black',
    fontWeight: 700,
    fontSize: "18px",
    marginLeft: '20px',
    fontFamily: 'Open Sans',
    marginTop: '20px',
  },
  addKpiButton: {
    marginBottom: "10px",
    marginTop: "10px",
    backgroundColor: "#FDD100",
    marginRight: "12px",
    color: "black",
    width: "130px",
    fontWeight: 500,
    fontSize: "16px",
  },
  totalWeightageContainer: {
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    backgroundColor: 'white',
    padding: "30px 15px",
    display: 'flex',
    borderRadius: "12px",
    flexDirection: 'row',
    marginLeft: '20px',
    marginTop: '50px',
    width: 'fit-content',
  },
  weightageCircle: {
    height: "70px",
    width: "70px",
    borderRadius: "50%",
    border: "5px solid #fdd100",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "25px",
  },
  weightageText: {
    fontWeight: 700,
    color: 'black',
    fontFamily: 'Open Sans',
    fontSize: "large",
  },
  totalWeightageText: {
    fontWeight: 700,
    fontSize: "28px  ",
    color: 'black',
    fontFamily: 'Open Sans',
    marginLeft: '20px',
  },
  weightageDescription: {
    fontWeight: 250,
    color: 'black',
    fontFamily: 'Open Sans',
    fontSize: "16px",
    marginLeft: '20px',
    marginTop: '20px',
  },
  weightageNote: {
    fontWeight: 250,
    color: 'black',
    fontFamily: 'Open Sans',
    fontSize: "16px",
    lineHeight: '0.5',
    marginLeft: '20px',
  },
  coreText: {
    color: 'black',
    fontWeight: 700,
    fontFamily: 'Open Sans',
    fontSize: "24px",
    marginLeft: '20px',
    marginTop: '20px',
  },
  submitButton: {
    fontFamily: 'Open Sans',
    backgroundColor: "#FDD100",
    marginRight: "20px",
    color: "black",
    width: "170px",
    fontWeight: 550,
    fontSize: "medium",
  },
  cancelButton: {
    fontFamily: 'Open Sans',
    color: "black",
    backgroundColor: "white",
    fontSize: "medium",
    width: "170px",
    fontWeight: 550,
  },
  template: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px 10px",
    backgroundColor: '#F3F4F5',
    position: "relative",
    height: "72px",
    alignItems: "center"
   },
   filterHeader: { 
    padding: "0px 0px 0px 22px", 
    display: "flex", 
    alignItems: "flex-start", 
    gap: "20px"
   }
};
// Customizable Area End
