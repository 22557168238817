import React from "react";

import {
    Box,
    Typography,
    Grid,
    // Customizable Area Start
    FormControl,
    FormLabel,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// Customizable Area End

import AddDkrDetailsController, {
    Props
} from "./AddDkrDetailsController";
import ViewDkrDetails from "./ViewDkrDetails.web";

export default class ViewKpiDetails extends AddDkrDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Box style={webStyles.maincontainer} id="viewkpi">
                <Grid container justifyContent="space-between" alignItems="center" data-test-id="viewkpisecond">
                    <Grid className="first" style={webStyles.leftContainer} item data-test-id="viewkpithird">
                        <Grid container spacing={2} alignItems="center" data-test-id="viewkpiforth">
                            <Grid item style={webStyles.rowDisplay}>
                                <ArrowBackIosIcon style={{ fontSize: "26px" }} />
                                <span
                                    style={webStyles.labelStyle1}
                                >
                                    Edit Template
                                </span>
                            </Grid>
                            <Grid item style={webStyles.rowDisplay}>
                                <ArrowBackIosIcon style={webStyles.labelStyle1} />
                                <span style={webStyles.labelStyle as React.CSSProperties}>
                                    View Kpi Details
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="second" style={webStyles.emptyContainer} item />
                </Grid>
                <Grid
                    container
                    alignItems="center"
                    style={webStyles.informationContainer as React.CSSProperties}
                >
                    <Grid
                        className="first"
                        item
                        style={webStyles.informationSubContainerHeading}
                        xs={12}
                    >
                        <div>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                    <span style={webStyles.labelStyle2 as React.CSSProperties}>KPI Details</span>
                                </FormLabel>

                            </FormControl>
                        </div>
                        <div className="form-field text-center" id="signupBlock4">
                            <div className="control" id="signupfieldcontrol2">
                                <button
                                    type="button"
                                    className="button submit"
                                    style={webStyles.editBtn as React.CSSProperties}
                                >
                                    Edit KPI Details
                                </button>
                            </div>
                        </div>

                    </Grid>
                    <Grid
                        className="first"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Type</span>
                            </FormLabel>

                            <span style={webStyles.valueStyle as React.CSSProperties}>Core</span>

                        </FormControl>

                    </Grid>
                    <Grid
                        className="second"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Basic Details</span>
                                <Typography variant="body1" style={{ color: "red" }}>*</Typography>
                            </FormLabel>
                            <Grid container style={{ display: "flex" }} xs={12}>
                                <Grid item xs={3}>
                                    <div className="form-block" id="signupBlock3">
                                        <form>
                                            <div
                                                className="form-field"
                                                id="signupform"
                                                style={webStyles.topThirty}
                                            >
                                                <span style={webStyles.labelStyleHeader as React.CSSProperties}>Title</span>
                                                <div
                                                    className="control"
                                                    id="signupfieldcontrol"
                                                    style={webStyles.marginLeft0}
                                                >
                                                    <span style={webStyles.valueStyle as React.CSSProperties}>KPI-1</span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="form-block" id="signupBlock3">
                                        <form>
                                            <div
                                                className="form-field"
                                                id="signupform"
                                                style={webStyles.topThirty}
                                            >
                                                <span style={webStyles.labelStyleHeader as React.CSSProperties}>Description</span>
                                                <div
                                                    className="control"
                                                    id="signupfieldcontrol"
                                                    style={webStyles.marginLeft0}
                                                >
                                                    <span style={webStyles.valueStyle as React.CSSProperties}>Sales Goal<br />Sales Goal : 1000000<br />Pro Rata Basis<br />MQR : 70%</span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </Grid>
                                <Grid item xs={3}>
                                    <div className="form-block" id="signupBlock3">
                                        <form>
                                            <div
                                                className="form-field"
                                                id="signupform"
                                                style={webStyles.topThirty}
                                            >
                                                <span style={webStyles.labelStyleHeader as React.CSSProperties}>Points(Weightage)</span>
                                                <div
                                                    className="control"
                                                    id="signupfieldcontrol"
                                                    style={webStyles.marginLeft0}
                                                >
                                                    <span style={webStyles.valueStyle as React.CSSProperties}>20 points</span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </Grid>
                            </Grid>
                        </FormControl>

                    </Grid >

                    <Grid
                        className="third"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Basic Details</span>
                                <Typography variant="body1" style={{ color: "red" }}>*</Typography>
                            </FormLabel>
                            <div className="form-block" id="signupBlock3">
                                <form>
                                    <div
                                        className="form-field"
                                        id="signupform"
                                        style={webStyles.topThirty}
                                    >
                                        <span style={webStyles.labelStyleHeader as React.CSSProperties}>Automatic</span>
                                        <div
                                            className="control"
                                            id="signupfieldcontrol"
                                            style={webStyles.marginLeft0}
                                        >
                                            <span style={webStyles.valueStyle as React.CSSProperties}>Yes</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </FormControl>

                    </Grid>
                    <Grid
                        className="first"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Monthly Goal Details</span>
                            </FormLabel>
                        </FormControl>
                        <Grid container style={{ display: "flex" }} xs={12}>
                            <Grid item xs={2}>
                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        <div
                                            className="form-field"
                                            id="signupform"
                                            style={webStyles.topThirty}
                                        >
                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>Goal Type</span>
                                            <div
                                                className="control"
                                                id="signupfieldcontrol"
                                                style={webStyles.marginLeft0}
                                            >
                                                <span style={webStyles.valueStyle as React.CSSProperties}>Number</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Grid>

                            <Grid item xs={5}>
                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        <div
                                            className="form-field"
                                            id="signupform"
                                            style={webStyles.topThirty}
                                        >
                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>DKR Calculation Formulas</span>
                                            <div
                                                className="control"
                                                id="signupfieldcontrol"
                                                style={webStyles.marginLeft0}
                                            >
                                                <span style={webStyles.valueStyle as React.CSSProperties}>Sum</span>


                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </Grid>
                            <Grid item xs={5}>
                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        <div
                                            className="form-field"
                                            id="signupform"
                                            style={webStyles.topThirty}
                                        >
                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>Goal Value (In numbers)</span>
                                            <div
                                                className="control"
                                                id="signupfieldcontrol"
                                                style={webStyles.marginLeft0}
                                            >
                                                <span style={webStyles.valueStyle as React.CSSProperties}>1,000,000</span>


                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        className="first"
                        item
                        style={webStyles.informationSubContainer}
                        xs={12}
                    >
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={webStyles.headerWithmandatory}>
                                <span style={webStyles.labelStyle as React.CSSProperties}>Analytics and Run rate</span>
                            </FormLabel>
                        </FormControl>
                        <Grid container style={{ display: "flex", alignItems: "last baseline" }}>
                            <Grid item xs={3}>
                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        <div
                                            className="form-field"
                                            id="signupform"
                                            style={webStyles.topThirty}
                                        >
                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>Run rate</span>
                                            <div
                                                className="control"
                                                id="signupfieldcontrol"
                                                style={webStyles.marginLeft0}
                                            >
                                                <span style={webStyles.valueStyle as React.CSSProperties}>Yes</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </Grid>
                            <Grid item xs={3}>
                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        <div
                                            className="form-field"
                                            id="signupform"
                                            style={webStyles.topThirty}
                                        >
                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>Part of Analytics</span>
                                            <div
                                                className="control"
                                                id="signupfieldcontrol"
                                                style={webStyles.marginLeft0}
                                            >
                                                <span style={webStyles.valueStyle as React.CSSProperties}>Yes</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </Grid>
                            <Grid item xs={6}>

                                <div className="form-block" id="signupBlock3">
                                    <form>
                                        <div
                                            className="form-field"
                                            id="signupform"
                                            style={webStyles.topThirty}
                                        >
                                            <span style={webStyles.labelStyleHeader as React.CSSProperties}>Analytics Parameter</span>
                                            <div
                                                className="control"
                                                id="signupfieldcontrol"
                                                style={webStyles.marginLeft0}
                                            >
                                                <span style={webStyles.valueStyle as React.CSSProperties}>Sales</span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid >
               <ViewDkrDetails navigation={undefined} id={""} classes={""}/>

            </Box >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyles = {
    maincontainer: {
        background: "#F3F4F5",
        height: "64px",
        marginTop: "32px",
        marginRight: "-20px"
    },
    leftContainer: {
        padding: "19px 0px 21px 38px"
    },
    emptyContainer: { padding: "0px 44px 0px 0px" },
    informationContainer: { padding: "0 32px", alignItems: "baseline", flexDirection: "column" },
    informationSubContainer: { marginTop: "24px", width: "800px" },
    informationSubContainerHeading: { marginTop: "24px", display: "flex", alignItems: "initial", justifyContent: "space-between", width: "90%" },
    topThirty: { marginTop: "30px" },
    
    labelStyle: {
        color: "black",
        fontSize: "24px",
        fontWeight: "300"
    },
    labelStyleHeader: {
        color: "#c8ccc9",
        fontSize: "22px",
        fontWeight: "bold"
    },
    valueStyle: {
        color: "black",
        fontSize: "22px",
        marginTop: "10px"
    },
    rowDisplay: { display: "flex", alignItems: "center" },
    labelStyle1: {
        color: "black",
        fontSize: "18px",
    },
    labelStyle2: {
        color: "black",
        fontSize: "24px",
        fontWeight: "bold"
    },
    editBtn: {
        height: "60px",
        fontSize: "20px",
        fontWeight: "normal"
    },
    marginLeft0: {
        marginLeft: 0,
        marginTop: 10
    },
    headerWithmandatory: {
        display: "flex",
        alignItems: "baseline",
        gap: "4px"
    },
};
// Customizable Area End
